import React, { useEffect } from "react";
import {
  getAllGateways,
  setSelectedGateway,
} from "../../../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import DepositGateway from "./depositGateway";
import { Navigate, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import DepositImg from "../../../../assets/images/icons/dc.png";
import bankImg from "../../../../assets/images/icons/bank.png";
import { getActiveBankDetails } from "../../../../redux/app/actions";
import BackIcon from "../../../../assets/svg/BackIcon";
import { Container } from "react-bootstrap";

import Rocket from "../../../../assets/images/DepositIMG/Rocket.png";
import Bkash from "../../../../assets/images/DepositIMG/Bkash.png";
import Nogot from "../../../../assets/images/DepositIMG/Nogot.jpeg";
import Upay from "../../../../assets/images/DepositIMG/Upay.png";
import Tron from "../../../../assets/images/DepositIMG/Tron.png";

const GatewaysList = () => {
  const dispatch = useDispatch();
  const { gatewaysList } = useSelector((state) => state.user);
  const { bankDetails } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);
  const isDemoUser = user?.mstrname === process.env.REACT_APP_DEMO_USER_ID;
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAllGateways());
    dispatch(getActiveBankDetails());
  }, []);

  const handleGatewayClick = (item) => {
    dispatch(setSelectedGateway(item));
    navigate(`/deposit/${item.name}`);
  };

  const handleBankTransferClick = () => {
    navigate(`/deposit/bank_transfer`);
  };
  return (
    <div>
      {isDemoUser && <Navigate to="/" />}
      <HeaderAfterLogin />
      <main className="main">
        <Container>
          <div className="d-flex justify-content-between align-items-center heading">
            <h4 className="">Deposit</h4>
            <a className="back-link" href="/">
              Back <BackIcon />
            </a>
          </div>

          <div className="gatewaySection">
            <div className="gatewaypart">
              <Tabs
                defaultActiveKey="Deposit"
                id="uncontrolled-tab-example"
                className=""
              >
                <Tab eventKey="Deposit" title="Online Deposit">
                  {gatewaysList?.deposits
                    ?.sort((a, b) => a.order - b.order)
                    ?.filter((f) => f.type === "auto")
                    .map((item, index) => {
                      return (
                        <div
                          className="cards1"
                          onClick={() => {
                            handleGatewayClick(item);
                          }}
                          key={index}
                        >
                          <div className="card-icon">
                            <img src={DepositImg} />
                          </div>

                          <p style={{ color: "#333" }}>{item?.name}</p>
                        </div>
                      );
                    })}
                </Tab>
                <Tab eventKey="bank" title="Bank Transfer">
                  {bankDetails && (
                    <div
                      className="cards1"
                      onClick={() => {
                        handleBankTransferClick();
                      }}
                    >
                      {" "}
                      <div className="card-icon">
                        <img src={bankImg} style={{ width: "66px" }} />
                      </div>
                      <p style={{ color: "#333" }}>{"Bank Transfer"}</p>
                    </div>
                  )}

                  {gatewaysList?.deposits
                    ?.sort((a, b) => a.order - b.order)
                    ?.filter((f) => f.type === "manual")
                    .map((item, index) => {
                      return (
                        <div
                          className="cards1"
                          onClick={() => {
                            handleGatewayClick(item);
                          }}
                          key={index}
                        >
                          <div className="card-icon">
                            {item.code === "m_rocket" ? (
                              <img src={Rocket} />
                            ) : item.code === "m_bkash" ? (
                              <img src={Bkash} />
                            ) : item.code === "m_nagad" ? (
                              <img src={Nogot} />
                            ) : item.code === "m_upay" ? (
                              <img src={Upay} />
                            ) : item.code === "m_binance_tron" ? (
                              <img src={Tron} />
                            ) : (
                              <img src={DepositImg} />
                            )}
                          </div>
                          <p>{item?.name}</p>
                        </div>
                      );
                    })}
                </Tab>
              </Tabs>
            </div>
          </div>
        </Container>
      </main>
    </div>
  );
};
export default GatewaysList;
