import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { AiTwotoneCopy } from "react-icons/ai";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate, useLocation } from "react-router-dom";
import { createWithDrawRequest } from "../../../../redux/user/actions";
import { APP_CONST } from "../../../../config/const";
import { useSelector } from "react-redux";
import { Toaster } from "../../../../utils/toaster";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const NagadWithdraw = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(false);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  useEffect(() => {
    if (location?.state?.amount) {
      setAmount(location?.state?.amount);
    }
  }, [location]);

  const validateData = (data) => {
    const dataToVerify = data ? data : formData;
    const { accountNo } = dataToVerify;
    let errors = {};
    if (accountNo == "" || !accountNo) {
      errors.accountNo = "Please enter accountNo";
    }
    if (!amount || amount == 0) {
      errors.amount = "Please enter amount";
    }
    setError(errors);
    return errors;
  };
  const handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    let data = { ...formData, [name]: value };
    validateData(data);
    setFormData(data);
  };

  const handleSubmit = async () => {
    if (Object.keys(validateData()).length === 0) {
      if (amount >= 0.91 && amount <= 25000) {
        setLoading(true);
        const result = await createWithDrawRequest(
          {
            ...formData,
            amount,
            mode: APP_CONST.GATEWAYS.M_NAGAD,
            mobileNo: user?.mobileno,
          },
          setLoading
        );
        if (result.data) {
          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Request Success");
        }
        setLoading(false);
        // .then((res) => {
        //   setLoading(false);
        //   if (res.data.error === false) {
        //     Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Request Success");
        //   }
        // })
        // .catch((err) => {
        //   setLoading(false);
        //   Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
        // });
      } else {
        alert("Amount must be greater than 300 and less than 25000");
      }
    }
  };
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main AccountWithdraw">
        <div className=" container">
          <div className="d-flex align-items-center justify-content-between">
            <h2 class="sectionTitle">Account Withdraw </h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>
          <div className="AccountWithdrawSection">
            <div className="row">
              <div className="col-md-6 col-12">
                <a href="#" className="copy-name">
                  <h5>Bank Name</h5>
                  <p>
                    Nagad
                    <CopyToClipboard
                      text={"Nagad"}
                      onCopy={() => {
                        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      }}
                    >
                      <AiTwotoneCopy style={{ cursor: "pointer" }} />
                    </CopyToClipboard>
                  </p>
                </a>
              </div>
              <div className="col-md-6 col-12">
                {/* <a href="#" className="copy-name">
                <h5>Nagad Wallet Number Name</h5>
                <p>
                  01794772522
                  <AiTwotoneCopy />
                </p>
              </a> */}
              </div>
            </div>
            <div className="RequestSection">
              <h2 class="sectionTitle">Request a Withdraw</h2>
              <div className="deposit-form mt-2">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Control
                      type="number"
                      placeholder="Amount*"
                      name="amount"
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      isInvalid={!!error.amount}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error?.amount}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Control
                      type="number"
                      placeholder="Your Nagad Wallet Number*"
                      name="accountNo"
                      onChange={handleChange}
                      isInvalid={!!error.accountNo}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error?.accountNo}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {/* <Form.Group className="mb-3" controlId="">
                <Form.Control
                  type="text"
                  placeholder="Your Name*"
                  name="utrNo"
                />
              </Form.Group> */}
                  {/* <Form.Group className="mb-3" controlId="">
                <Form.Control
                  type="text"
                  placeholder="Transaction ID"
                  name="txnImage"
                />
              </Form.Group> */}
                </Form>
                <div className="payment-button">
                  <Button
                    variant="primary"
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={loading}
                  >
                    {loading ? "Updating..." : "Make Payment"}
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Return
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
