export const slotsData = {
  slots1: {
    redirectUrl: "/casino/ezugi/gonzoquest",
    imgUrl: "./images/slots/slots-imgs/Gonzos_Quest.png",
    name: "Gonzo Treasure Hunts",
  },
  slots2: {
    redirectUrl: "/casino/ezugi/asgardianstones",
    imgUrl: "./images/slots/slots-imgs/asgardianstones.png",
    name: "Asgardian Stones",
  },
  slots3: {
    redirectUrl: "/casino/ezugi/secrets",
    imgUrl: "./images/slots/slots-imgs/secrets-of-atlantis.png",
    name: "Secrets of Atlantis",
  },
  slots4: {
    redirectUrl: "/casino/ezugi/grandwheel",
    imgUrl: "./images/slots/slots-imgs/grand-wheel.png",
    name: "Grand Wheel",
  },
  slots5: {
    redirectUrl: "/casino/ezugi/Strike777",
    imgUrl: "./images/slots/slots-imgs/777strike.png",
    name: "777 Strike",
  },
  slots6: {
    redirectUrl: "/casino/ezugi/Nights1000",
    imgUrl: "./images/slots/slots-imgs/10001-nights.png",
    name: "10001 Nights",
  },
  slots7: {
    redirectUrl: "/casino/ezugi/CashUltimate",
    imgUrl: "./images/slots/slots-imgs/cash_ultimate.png",
    name: "Cash Ultimate",
  },
  slots8: {
    redirectUrl: "/casino/ezugi/CashVolt",
    imgUrl: "./images/slots/slots-imgs/cash_volt.png",
    name: "Cash Volt",
  },
  slots9: {
    redirectUrl: "/casino/ezugi/DazzleMegaways",
    imgUrl: "./images/slots/slots-imgs/dazzle_me_megaways.png",
    name: "Dazzle Me Megaways",
  },
  slots10: {
    redirectUrl: "/casino/ezugi/DivinieFortuneMegaways",
    imgUrl: "./images/slots/slots-imgs/divine_fortune_megaways.png",
    name: "Divine Fortune Megaways",
  },
  slots11: {
    redirectUrl: "/casino/ezugi/Dynamiteriches",
    imgUrl: "./images/slots/slots-imgs/dynamite_riches.png",
    name: "Dynamite Riches",
  },
  slots12: {
    redirectUrl: "/casino/ezugi/FortuneHouse",
    imgUrl: "./images/slots/slots-imgs/fortunehouse.png",
    name: "Fotune House",
  },
  slots13: {
    redirectUrl: "/casino/ezugi/FruitShopMegaways",
    imgUrl: "./images/slots/slots-imgs/Fruit_Shop_Megaways.png",
    name: "Fruit Shop Megaways",
  },
  slots14: {
    redirectUrl: "/casino/ezugi/GoldenCryptex",
    imgUrl: "./images/slots/slots-imgs/Golden_Cryptex.png",
    name: "Golden Cryptex",
  },
  slots15: {
    redirectUrl: "/casino/ezugi/GoldenTsar",
    imgUrl: "./images/slots/slots-imgs/Golden_Tsar.png",
    name: "Golden Tsar",
  },
  slots16: {
    redirectUrl: "/casino/ezugi/GorillaKingdom",
    imgUrl: "./images/slots/slots-imgs/Gorilla_Kingdom.png",
    name: "Gorilla Kingdom",
  },
  slots17: {
    redirectUrl: "/casino/ezugi/MystryReels",
    imgUrl: "./images/slots/slots-imgs/Mystery_Reels_Megaways.png",
    name: "Mystery Reels Megaways",
  },
  slots18: {
    redirectUrl: "/casino/ezugi/RageoftheSea",
    imgUrl: "./images/slots/slots-imgs/Rage_Of_The_Seas.png",
    name: "Rage Of The Seas",
  },
  slots19: {
    redirectUrl: "/casino/ezugi/ReelKingMegaways",
    imgUrl: "./images/slots/slots-imgs/Reel_King_Mega.png",
    name: "Reel King Mega",
  },
  slots20: {
    redirectUrl: "/casino/ezugi/TurnFortune",
    imgUrl: "./images/slots/slots-imgs/Turn_Your_Fortune.png",
    name: "Turn Your Fortune",
  },
  slots21: {
    redirectUrl: "/casino/ezugi/TwinSpinMegaways",
    imgUrl: "./images/slots/slots-imgs/Twin_Spin_Megaways.png",
    name: "Twin Spin Megaways",
  },
  slots22: {
    redirectUrl: "/casino/ezugi/WillysChills",
    imgUrl: "./images/slots/slots-imgs/Willys_Hot_Chillies.png",
    name: "Willys Hot Chillies",
  },
  slots23: {
    redirectUrl: "/casino/ezugi/ChineseTreasures",
    imgUrl: "./images/slots/slots-imgs/Chinese-Treasures.png",
    name: "Chinese Treasures",
  },
  slots24: {
    redirectUrl: "/casino/ezugi/DragonLuck",
    imgUrl: "./images/slots/slots-imgs/Dragon's-Luck.png",
    name: "Dragon's Luck",
  },
  slots25: {
    redirectUrl: "/casino/ezugi/DragonFire",
    imgUrl: "./images/slots/slots-imgs/Dragon's-Fire.png",
    name: "Dragon's Fire",
  },
  slots26: {
    redirectUrl: "/casino/ezugi/DragonLuckDeluxe",
    imgUrl: "./images/slots/slots-imgs/Dragon's-Luck-Deluxe.png",
    name: "Dragon's Luck Deluxe",
  },
  slots27: {
    redirectUrl: "/casino/ezugi/DragonLuckReel",
    imgUrl: "./images/slots/slots-imgs/Dragon's-Luck-Power-Reels.png",
    name: "Dragon's Luck Power Reels",
  },
  slots28: {
    redirectUrl: "/casino/ezugi/EpicJourney",
    imgUrl: "./images/slots/slots-imgs/Epic-Journey.png",
    name: "Epic Journey",
  },
  slots29: {
    redirectUrl: "/casino/ezugi/GodWealth",
    imgUrl: "./images/slots/slots-imgs/God-Of-Wealth.png",
    name: "God Of Wealth",
  },
  slots30: {
    redirectUrl: "/casino/ezugi/LuckyFortune",
    imgUrl: "./images/slots/slots-imgs/Lucky-Fortune-Cat.png",
    name: "Lucky Fortune Cat",
  },
  slots31: {
    redirectUrl: "/casino/ezugi/MagicGate",
    imgUrl: "./images/slots/slots-imgs/Magic-Gate.png",
    name: "Magic Gate",
  },
  slots32: {
    redirectUrl: "/casino/ezugi/GoldenTemple",
    imgUrl: "./images/slots/slots-imgs/Golden-Temple.png",
    name: "Golden Temple",
  },
  slots33: {
    redirectUrl: "/casino/ezugi/PhoenixPower",
    imgUrl: "./images/slots/slots-imgs/Phoenix-Fire-Power-Reels.png",
    name: "Phoenix Fire Power Reels",
  },
  slots34: {
    redirectUrl: "/casino/ezugi/GoldenOffer",
    imgUrl: "./images/slots/slots-imgs/Golden-Offer.png",
    name: "Golden Offer",
  },
  slots35: {
    redirectUrl: "/casino/ezugi/RAsLegend",
    imgUrl: "./images/slots/slots-imgs/RA's-Legend.png",
    name: "RA's Legend",
  },
  slots36: {
    redirectUrl: "/casino/ezugi/GonzoMegawaya",
    imgUrl: "./images/slots/slots-imgs/Gonzo's-Quest-Megaways.png",
    name: "Gonzo's Quest Megaways",
  },
  slots37: {
    redirectUrl: "/casino/ezugi/RainbowJAcket",
    imgUrl: "./images/slots/slots-imgs/Rainbow-Jackpots.png",
    name: "Rainbow Jackpots",
  },
  slots38: {
    redirectUrl: "/casino/ezugi/MegaPyramid",
    imgUrl: "./images/slots/slots-imgs/Mega-Pyramid.png",
    name: "Mega Pyramid",
  },
  slots39: {
    redirectUrl: "/casino/ezugi/Jimi_Hendrix",
    imgUrl: "./images/slots/slots-imgs/Jimi_Hendrix.png",
    name: "Jimi Hendrix",
  },
  slots40: {
    redirectUrl: "/casino/ezugi/Jumanji",
    imgUrl: "./images/slots/slots-imgs/Jumanji.png",
    name: "Jumanji",
  },
  slots41: {
    redirectUrl: "/casino/ezugi/MotorheadVideoSlot",
    imgUrl: "./images/slots/slots-imgs/Motorhead_Video_Slot.png",
    name: "Motorhead Video Slot",
  },
  slots42: {
    redirectUrl: "/casino/ezugi/Starburst",
    imgUrl: "./images/slots/slots-imgs/Starburst.png",
    name: "Starburst",
  },
  slots43: {
    redirectUrl: "/casino/ezugi/WarofGod",
    imgUrl: "./images/slots/slots-imgs/War-Of-Gods.png",
    name: "War Of Gods",
  },
  slots44: {
    redirectUrl: "/casino/ezugi/Christmas",
    imgUrl: "./images/slots/slots-imgs/Aloha-Christmas.png",
    name: "Aloha Christmas",
  },
  slots45: {
    redirectUrl: "/casino/ezugi/ClusterPays",
    imgUrl: "./images/slots/slots-imgs/Aloha-Cluster-Pays.png",
    name: "Aloha Cluster Pays",
  },
  slots46: {
    redirectUrl: "/casino/ezugi/AncientsBlessing",
    imgUrl: "./images/slots/slots-imgs/Ancients-Blessing.png",
    name: "Ancients Blessing",
  },
  slots47: {
    redirectUrl: "/casino/ezugi/AurumCodex",
    imgUrl: "./images/slots/slots-imgs/Aurum-Codex.png",
    name: "Aurum Codex",
  },
  slots48: {
    redirectUrl: "/casino/ezugi/GunsNRoses",
    imgUrl: "./images/slots/slots-imgs/Guns_N_Roses.png",
    name: "Guns N Roses",
  },
  slots49: {
    redirectUrl: "/casino/ezugi/BorisandBoo",
    imgUrl: "./images/slots/slots-imgs/Betty-Boris-and-Boo.png",
    name: "Betty Boris and Boo",
  },
  slots50: {
    redirectUrl: "/casino/ezugi/WildWest",
    imgUrl:
      "./images/slots/slots-imgs/Wild-Wild-West-The-Great-Train-Heist.png",
    name: "Wild West The Great Train Heist",
  },
  slots51: {
    redirectUrl: "/casino/ezugi/SteamTower",
    imgUrl: "./images/slots/slots-imgs/Steam-Tower.png",
    name: "Steam Tower",
  },
  slots52: {
    redirectUrl: "/casino/ezugi/ZeusLightning",
    imgUrl: "./images/slots/slots-imgs/Zeus-Lightning-Power-Reels.png",
    name: "Zeus Lightning Power Reels",
  },
  slots53: {
    redirectUrl: "/casino/ezugi/WingsofRa",
    imgUrl: "./images/slots/slots-imgs/Wings-of-Ra.png",
    name: "Wings of Ra",
  },
  slots54: {
    redirectUrl: "/casino/ezugi/Trillionaire",
    imgUrl: "./images/slots/slots-imgs/Trillionaire.png",
    name: "Trillionaire",
  },
  slots55: {
    redirectUrl: "/casino/ezugi/TreasureMine",
    imgUrl: "./images/slots/slots-imgs/Treasure-Mine.png",
    name: "Treasure Mine",
  },
  slots56: {
    redirectUrl: "/casino/ezugi/ThorVengeance",
    imgUrl: "./images/slots/slots-imgs/Thor's-Vengeance.png",
    name: "Thor's Vengeance",
  },
  slots57: {
    redirectUrl: "/casino/ezugi/RegalStreak",
    imgUrl: "./images/slots/slots-imgs/Regal-Streak.png",
    name: "Regal Streak",
  },
  slots58: {
    redirectUrl: "/casino/ezugi/RegalBeasts",
    imgUrl: "./images/slots/slots-imgs/Regal-Beasts.png",
    name: "Regal Beasts",
  },
  slots59: {
    redirectUrl: "/casino/ezugi/ReelKeeper",
    imgUrl: "./images/slots/slots-imgs/Reel-Keeper.png",
    name: "Reel Keeper",
  },
  slots60: {
    redirectUrl: "/casino/ezugi/RainbowJackpotsPowerLines",
    imgUrl: "./images/slots/slots-imgs/Rainbow-Jackpots-Power-Lines.png",
    name: "Rainbow Jackpots Power Lines",
  },
  slots61: {
    redirectUrl: "/casino/ezugi/PiggyRichesMegaways",
    imgUrl: "./images/slots/slots-imgs/Piggy-Riches-Megaways.png",
    name: "Piggy Riches Megaways",
  },
  slots62: {
    redirectUrl: "/casino/ezugi/JingleBells",
    imgUrl: "./images/slots/slots-imgs/Jingle-Bells.png",
    name: "Jingle Bells",
  },
  slots63: {
    redirectUrl: "/casino/ezugi/JackinaPot",
    imgUrl: "./images/slots/slots-imgs/Jack-in-a-Pot.png",
    name: "Jack in a Pot",
  },
  slots64: {
    redirectUrl: "/casino/ezugi/FiveStar",
    imgUrl: "./images/slots/slots-imgs/Five-Star.png",
    name: "Five Star",
  },
  slots65: {
    redirectUrl: "/casino/ezugi/BountyRaid",
    imgUrl: "./images/slots/slots-imgs/Bounty-Raid.png",
    name: "Bounty Raid",
  },
  slots66: {
    redirectUrl: "/casino/ezugi/MysteryReels2",
    imgUrl: "./images/slots/slots-imgs/Mystery-Reels.png",
    name: "Mystery Reels",
  },
  slots67: {
    redirectUrl: "/casino/ezugi/DiamondBlitz",
    imgUrl: "./images/slots/slots-imgs/Diamond-Blitz.png",
    name: "Diamond Blitz",
  },
  slots68: {
    redirectUrl: "/casino/ezugi/DragonFireMegaways",
    imgUrl: "./images/slots/slots-imgs/Dragon's-Fire-Megaways.png",
    name: "Dragon's Fire Megaways",
  },
  slots69: {
    redirectUrl: "/casino/ezugi/Rome",
    imgUrl: "./images/slots/slots-imgs/Rome-The-Golden-Age.png",
    name: "Rome-The Golden Age",
  },
  slots70: {
    redirectUrl: "/casino/ezugi/ReelRush",
    imgUrl: "./images/slots/slots-imgs/Reel-Rush.png",
    name: "Reel Rush",
  },
  slots71: {
    redirectUrl: "/casino/ezugi/BloodSuckers",
    imgUrl: "./images/slots/slots-imgs/Blood-Suckers.png",
    name: "Blood Suckers",
  },
  slots72: {
    redirectUrl: "/casino/ezugi/BloodSuckers2",
    imgUrl: "./images/slots/slots-imgs/Blood-Suckers-2.png",
    name: "Blood Suckers 2",
  },
  slots73: {
    redirectUrl: "/casino/ezugi/Pyramid",
    imgUrl: "./images/slots/slots-imgs/Pyramid-Quest-for-Immortality.png",
    name: "Pyramid Quest for Immortality",
  },
  slots74: {
    redirectUrl: "/casino/ezugi/ButterflyStaxx",
    imgUrl: "./images/slots/slots-imgs/Butterfly-Staxx.png",
    name: "Butterfly Staxx",
  },
  slots75: {
    redirectUrl: "/casino/ezugi/ButterflyStaxx2",
    imgUrl: "./images/slots/slots-imgs/Butterfly-Staxx-2.png",
    name: "Butterfly Staxx 2",
  },
  slots76: {
    redirectUrl: "/casino/ezugi/PiggyRiches",
    imgUrl: "./images/slots/slots-imgs/Piggy-Riches.png",
    name: "Piggy Riches",
  },
  slots77: {
    redirectUrl: "/casino/ezugi/DarkKing",
    imgUrl: "./images/slots/slots-imgs/Dark-King-Forbidden-Riches.png",
    name: "Dark King Forbidden Riches",
  },
  slots78: {
    redirectUrl: "/casino/ezugi/JingleBellsPowerReels",
    imgUrl: "./images/slots/slots-imgs/Jingle-Bells-Power-Reels.png",
    name: "Jingle Bells Power Reels",
  },
  slots79: {
    redirectUrl: "/casino/ezugi/Dazzle",
    imgUrl: "./images/slots/slots-imgs/Dazzle-Me.png",
    name: "Dazzle Me",
  },
  slots80: {
    redirectUrl: "/casino/ezugi/JackHammer",
    imgUrl: "./images/slots/slots-imgs/Jack-Hammer.png",
    name: "Jack Hammer",
  },
  slots81: {
    redirectUrl: "/casino/ezugi/JackHammer2",
    imgUrl: "./images/slots/slots-imgs/Jack-Hammer-2-Fishy-Business.png",
    name: "Jack Hammer 2 Fishy Business",
  },
  slots82: {
    redirectUrl: "/casino/ezugi/DeadorAlive",
    imgUrl: "./images/slots/slots-imgs/Dead-or-Alive.png",
    name: "Dead or Alive",
  },
  slots83: {
    redirectUrl: "/casino/ezugi/DeadorAlive2",
    imgUrl: "./images/slots/slots-imgs/Dead-or-Alive-2.png",
    name: "Dead or Alive 2",
  },
  slots84: {
    redirectUrl: "/casino/ezugi/DeadorAlive2FeatureBuy",
    imgUrl: "./images/slots/slots-imgs/Dead-or-Alive-2-Feature-Buy.png",
    name: "Dead or Alive 2 Feature Buy",
  },
  slots85: {
    redirectUrl: "/casino/ezugi/JackandtheBeanstalk",
    imgUrl: "./images/slots/slots-imgs/Jack-and-the-Beanstalk.png",
    name: "Jack and the Beanstalk",
  },
  slots86: {
    redirectUrl: "/casino/ezugi/JingleSpin",
    imgUrl: "./images/slots/slots-imgs/Jingle-Spin.png",
    name: "Jingle Spin",
  },
  slots87: {
    redirectUrl: "/casino/ezugi/GordonRamsayHellsKitchen",
    imgUrl: "./images/slots/slots-imgs/Gordon_Ramsay_Hells_Kitchen.png",
    name: "Gordon Ramsay Hells Kitchen",
  },
  slots88: {
    redirectUrl: "/casino/ezugi/Elements",
    imgUrl: "./images/slots/slots-imgs/Elements-The-Awakening.png",
    name: "Elements The Awakening",
  },
  slots89: {
    redirectUrl: "/casino/ezugi/GonzosQuest",
    imgUrl: "./images/slots/slots-imgs/Gonzos_Quest.png",
    name: "Gonzos Quest",
  },
  slots90: {
    redirectUrl: "/casino/ezugi/FinnandtheSwirlySpin",
    imgUrl: "./images/slots/slots-imgs/Finn-and-the-Swirly-Spin.png",
    name: "Finn and the Swirly Spin",
  },
  slots91: {
    redirectUrl: "/casino/ezugi/FruitShop",
    imgUrl: "./images/slots/slots-imgs/Fruit-Shop.png",
    name: "Fruit Shop",
  },
  // slots92: {
  //   redirectUrl: "/casino/ezugi/DazzleMegaways",
  //   imgUrl: "./images/slots/slots-imgs/Dazzle-Me.png
  //   name: "Dazzle Me",
  // },
  slots93: {
    redirectUrl: "/casino/ezugi/DoubleStacks",
    imgUrl: "./images/slots/slots-imgs/Double-Stacks.png",
    name: "Double Stacks",
  },
  slots94: {
    redirectUrl: "/casino/ezugi/FairytaleLegends",
    imgUrl: "./images/slots/slots-imgs/Fairytale-Legends-Red-Riding-Hood.png",
    name: "Fairytale Legends Red Riding Hood",
  },
  slots95: {
    redirectUrl: "/casino/ezugi/Flowers",
    imgUrl: "./images/slots/slots-imgs/Flowers.png",
    name: "Flowers",
  },
  slots96: {
    redirectUrl: "/casino/ezugi/FruitShopChristmasEdition",
    imgUrl: "./images/slots/slots-imgs/Fruit-Shop-Christmas-Edition.png",
    name: "Fruit Shop Christmas Edition",
  },
  slots97: {
    redirectUrl: "/casino/ezugi/Hotline",
    imgUrl: "./images/slots/slots-imgs/Hotline.png",
    name: "Hotline",
  },
  slots98: {
    redirectUrl: "/casino/ezugi/Hotline2",
    imgUrl: "./images/slots/slots-imgs/Hotline-2.png",
    name: "Hotline 2",
  },
  slots99: {
    redirectUrl: "/casino/ezugi/TheInvisibleMan",
    imgUrl: "./images/slots/slots-imgs/The_Invisible_Man.png",
    name: "The Invisible Man",
  },
  slots100: {
    redirectUrl: "/casino/ezugi/IrishPotLuck",
    imgUrl: "./images/slots/slots-imgs/Irish-Pot-Luck.png",
    name: "Irish Pot Luck",
  },
  slots101: {
    redirectUrl: "/casino/ezugi/FruitSpin",
    imgUrl: "./images/slots/slots-imgs/Fruit-Spin.png",
    name: "Fruit Spin",
  },
  slots102: {
    redirectUrl: "/casino/ezugi/JungleSpirit",
    imgUrl: "./images/slots/slots-imgs/Jungle-Spirit-Call-of-the-Wild.png",
    name: "Jungle Spirit Call of the Wild",
  },
  slots103: {
    redirectUrl: "/casino/ezugi/KoiPrincess",
    imgUrl: "./images/slots/slots-imgs/Koi-Princess.png",
    name: "Koi Princess",
  },
  slots104: {
    redirectUrl: "/casino/ezugi/DriveMultiplierMayhem",
    imgUrl: "./images/slots/slots-imgs/Drive-Multiplier-Mayhem.png",
    name: "Drive Multiplier Mayhem",
  },
  slots105: {
    redirectUrl: "/casino/ezugi/RageoftheSeas",
    imgUrl: "./images/slots/slots-imgs/Rage-of-the-Seas.png",
    name: "Rage of the Seas",
  },
  slots106: {
    redirectUrl: "/casino/ezugi/RichesofMidgard",
    imgUrl: "./images/slots/slots-imgs/Riches-of-Midgard-Land-and-Expand.png",
    name: "Riches of Midgard Land and Expand",
  },
  slots107: {
    redirectUrl: "/casino/ezugi/ScruffyDuck",
    imgUrl: "./images/slots/slots-imgs/Scruffy-Duck.png",
    name: "Scruffy Duck",
  },
  slots108: {
    redirectUrl: "/casino/ezugi/SecretoftheStones",
    imgUrl: "./images/slots/slots-imgs/Secret-of-the-Stones.png",
    name: "Secret of the Stones",
  },
  slots109: {
    redirectUrl: "/casino/ezugi/SecretsofChristmas",
    imgUrl: "./images/slots/slots-imgs/Secrets-of-Christmas.png",
    name: "Secret of Christmas",
  },
  slots110: {
    redirectUrl: "/casino/ezugi/SerengetiKings",
    imgUrl: "./images/slots/slots-imgs/Serengeti-Kings.png",
    name: "Serengeti Kings",
  },
  slots111: {
    redirectUrl: "/casino/ezugi/SpaceWars",
    imgUrl: "./images/slots/slots-imgs/Space-Wars.png",
    name: "Space Wars",
  },
  slots112: {
    redirectUrl: "/casino/ezugi/Victorious",
    imgUrl: "./images/slots/slots-imgs/Victorious.png",
    name: "Victorious",
  },
  slots113: {
    redirectUrl: "/casino/ezugi/Warlords",
    imgUrl: "./images/slots/slots-imgs/Warlords-Crystal-of-Power.png",
    name: "Warlords Crystal of Power",
  },
  slots114: {
    redirectUrl: "/casino/ezugi/WildWater",
    imgUrl: "./images/slots/slots-imgs/Wild-Water.png",
    name: "Wild Water",
  },
  slots115: {
    redirectUrl: "/casino/ezugi/WildWorlds",
    imgUrl: "./images/slots/slots-imgs/Wild-Worlds.png",
    name: "Wild Worlds",
  },
  slots116: {
    redirectUrl: "/casino/ezugi/Squad4",
    imgUrl: "./images/slots/slots-imgs/4-Squad.png",
    name: "4 Squad",
  },
  slots117: {
    redirectUrl: "/casino/ezugi/Families5",
    imgUrl: "./images/slots/slots-imgs/5-Families.png",
    name: "5 Families",
  },
  slots118: {
    redirectUrl: "/casino/ezugi/AgentRoyale",
    imgUrl: "./images/slots/slots-imgs/Agent-Royale.png",
    name: "Agent Royale",
  },
  slots119: {
    redirectUrl: "/casino/ezugi/ArcadeBomb",
    imgUrl: "./images/slots/slots-imgs/Arcade-Bomb.png",
    name: "Arcade Bomb",
  },
  slots120: {
    redirectUrl: "/casino/ezugi/Atlantis",
    imgUrl: "./images/slots/slots-imgs/Atlantis.png",
    name: "Atlantis",
  },
  slots121: {
    redirectUrl: "/casino/ezugi/AztecSpins",
    imgUrl: "./images/slots/slots-imgs/Aztec-Spins.png",
    name: "Aztec Spins",
  },
  slots122: {
    redirectUrl: "/casino/ezugi/CinderellaBall",
    imgUrl: "./images/slots/slots-imgs/Cinderella`s-Ball.png",
    name: "Cinderella`s Ball",
  },
  slots123: {
    redirectUrl: "/casino/ezugi/CirquedelaFortune",
    imgUrl: "./images/slots/slots-imgs/Cirque-de-la-Fortune.png",
    name: "Cirque de la Fortune",
  },
  slots124: {
    redirectUrl: "/casino/ezugi/ClashoftheBeasts",
    imgUrl: "./images/slots/slots-imgs/Clash-of-the-Beasts.png",
    name: "Clash of the Beasts",
  },
  slots125: {
    redirectUrl: "/casino/ezugi/CrazyGenie",
    imgUrl: "./images/slots/slots-imgs/Crazy-Genie.png",
    name: "Crazy Genie",
  },
  slots126: {
    redirectUrl: "/casino/ezugi/CrystalMirror",
    imgUrl: "./images/slots/slots-imgs/Crystal-Mirror.png",
    name: "Crystal Mirror",
  },
  slots127: {
    redirectUrl: "/casino/ezugi/DaVinciMystery",
    imgUrl: "./images/slots/slots-imgs/Da-Vinci`s-Mystery.png",
    name: "Da-Vinci`s Mystery",
  },
  slots128: {
    redirectUrl: "/casino/ezugi/DevilNumber",
    imgUrl: "./images/slots/slots-imgs/Devil`s-Number.png",
    name: "Devil`s Number",
  },
  slots129: {
    redirectUrl: "/casino/ezugi/DiceDice",
    imgUrl: "./images/slots/slots-imgs/Dice-Dice-Dice.png",
    name: "Dice Dice Dice",
  },
  slots130: {
    redirectUrl: "/casino/ezugi/FiveStarsPowerReels",
    imgUrl: "./images/slots/slots-imgs/Five-Stars-Power-Reels.png",
    name: "Five Stars Power Reels",
  },
  slots131: {
    redirectUrl: "/casino/ezugi/GemsGoneWildPowerReels",
    imgUrl: "./images/slots/slots-imgs/Gems-Gone-Wild-Power-Reels.png",
    name: "Gems Gone Wild Power Reels",
  },
  slots132: {
    redirectUrl: "/casino/ezugi/GemsGoneWild",
    imgUrl: "./images/slots/slots-imgs/Gems-Gone-Wild.png",
    name: "Gems Gone Wild",
  },
  slots133: {
    redirectUrl: "/casino/ezugi/GoldenLeprechaunMegaways",
    imgUrl: "./images/slots/slots-imgs/Golden-Leprechaun-Megaways.png",
    name: "GoldenLeprechaun Megaways",
  },
  slots134: {
    redirectUrl: "/casino/ezugi/HoardofPoseidon",
    imgUrl: "./images/slots/slots-imgs/Hoard-of-Poseidon.png",
    name: "Hoard of Poseidon",
  },
  slots135: {
    redirectUrl: "/casino/ezugi/JewelScarabs",
    imgUrl: "./images/slots/slots-imgs/Jewel-Scarabs.png",
    name: "Jewel Scarabs",
  },
  slots136: {
    redirectUrl: "/casino/ezugi/LegendofAthena",
    imgUrl: "./images/slots/slots-imgs/Legend-of-Athena.png",
    name: "Legend of Athena",
  },
  slots137: {
    redirectUrl: "/casino/ezugi/LuckyHalloween",
    imgUrl: "./images/slots/slots-imgs/Lucky-Halloween.png",
    name: "Lucky Halloween",
  },
  slots138: {
    redirectUrl: "/casino/ezugi/LuckyLittleDevil",
    imgUrl: "./images/slots/slots-imgs/Lucky-Little-Devil.png",
    name: "Lucky Little Devil",
  },
  slots139: {
    redirectUrl: "/casino/ezugi/LuckyValentine",
    imgUrl: "./images/slots/slots-imgs/Lucky-Valentine.png",
    name: "Lucky Valentine",
  },
  slots140: {
    redirectUrl: "/casino/ezugi/LuckyWizard",
    imgUrl: "./images/slots/slots-imgs/Lucky-Wizard.png",
    name: "Lucky Wizard",
  },
  slots141: {
    redirectUrl: "/casino/ezugi/Masquerade",
    imgUrl: "./images/slots/slots-imgs/Masquerade.png",
    name: "Masquerade",
  },
  slots142: {
    redirectUrl: "/casino/ezugi/MayanGods",
    imgUrl: "./images/slots/slots-imgs/Mayan-Gods.png",
    name: "Mayan Gods",
  },
  slots143: {
    redirectUrl: "/casino/ezugi/MegaDragon",
    imgUrl: "./images/slots/slots-imgs/Mega-Dragon.png",
    name: "Mega Dragon",
  },
  slots144: {
    redirectUrl: "/casino/ezugi/MegaJade",
    imgUrl: "./images/slots/slots-imgs/Mega-Jade.png",
    name: "Mega Jade",
  },
  slots145: {
    redirectUrl: "/casino/ezugi/MegaRise",
    imgUrl: "./images/slots/slots-imgs/Mega-Rise.png",
    name: "Mega Rise",
  },
  slots146: {
    redirectUrl: "/casino/ezugi/MultiplierRiches",
    imgUrl: "./images/slots/slots-imgs/Multiplier-Riches.png",
    name: "Multiplier Riches",
  },
  slots147: {
    redirectUrl: "/casino/ezugi/MysteryReelsMegaways",
    imgUrl: "./images/slots/slots-imgs/Mystery_Reels_Megaways.png",
    name: "Mystery Reels Megaways",
  },
  slots148: {
    redirectUrl: "/casino/ezugi/NinjaWays",
    imgUrl: "./images/slots/slots-imgs/Ninja-Ways.png",
    name: "Ninja Ways",
  },
  slots149: {
    redirectUrl: "/casino/ezugi/PathofDestiny",
    imgUrl: "./images/slots/slots-imgs/Path-of-Destiny.png",
    name: "Path of Destiny",
  },
  slots150: {
    redirectUrl: "/casino/ezugi/PersianFortune",
    imgUrl: "./images/slots/slots-imgs/Persian-Fortune.png",
    name: "Persian Fortune",
  },
  slots151: {
    redirectUrl: "/casino/ezugi/ReelHeist",
    imgUrl: "./images/slots/slots-imgs/Reel-Heist.png",
    name: "Reel Heist",
  },
  slots152: {
    redirectUrl: "/casino/ezugi/RobinHoodWildForest",
    imgUrl: "./images/slots/slots-imgs/Robin-Hood`s-Wild-Forest.png",
    name: "Robin Hood`s Wild Forest",
  },
  slots153: {
    redirectUrl: "/casino/ezugi/SnowWild",
    imgUrl: "./images/slots/slots-imgs/Snow-Wild-And-The-7-Features.png",
    name: "Snow Wild And The 7 Features",
  },
  slots154: {
    redirectUrl: "/casino/ezugi/SylvanSpirits",
    imgUrl: "./images/slots/slots-imgs/Sylvan-Spirits.png",
    name: "Sylvan Spirits",
  },
  slots155: {
    redirectUrl: "/casino/ezugi/TheGreatestTrainRobbery",
    imgUrl: "./images/slots/slots-imgs/The-Greatest-Train-Robbery.png",
    name: "The Greatest Train Robbery",
  },
  slots156: {
    redirectUrl: "/casino/ezugi/TheWildHatter",
    imgUrl: "./images/slots/slots-imgs/The-Wild-Hatter.png",
    name: "The Wild Hatter",
  },
  slots157: {
    redirectUrl: "/casino/ezugi/ThorLightning",
    imgUrl: "./images/slots/slots-imgs/Thor`s-Lightning.png",
    name: "Thor`s Lightning",
  },
  slots158: {
    redirectUrl: "/casino/ezugi/TikiFruits",
    imgUrl: "./images/slots/slots-imgs/Tiki-Fruits.png",
    name: "Tiki Fruits",
  },

  slots159: {
    redirectUrl: "/casino/ezugi/TotemLightning",
    imgUrl: "./images/slots/slots-imgs/Totem-Lightning.png",
    name: "Totem Lightning",
  },
  slots160: {
    redirectUrl: "/casino/ezugi/VaultCracker",
    imgUrl: "./images/slots/slots-imgs/Vault-Cracker.png",
    name: "Vault Cracker",
  },
  slots161: {
    redirectUrl: "/casino/ezugi/VaultofAnubis",
    imgUrl: "./images/slots/slots-imgs/Vault-of-Anubis.png",
    name: "Vault of Anubis",
  },
  slots162: {
    redirectUrl: "/casino/ezugi/WellofWishes",
    imgUrl: "./images/slots/slots-imgs/Well-of-Wishes.png",
    name: "Well of Wishes",
  },
  slots163: {
    redirectUrl: "/casino/ezugi/WildCatsMultiline",
    imgUrl: "./images/slots/slots-imgs/Wild-Cats-Multiline.png",
    name: "Wild Cats Multiline",
  },
  slots164: {
    redirectUrl: "/casino/ezugi/WildOClock",
    imgUrl: "./images/slots/slots-imgs/Wild-O`Clock.png",
    name: "Wild O`Clock",
  },
  slots165: {
    redirectUrl: "/casino/ezugi/WildChest",
    imgUrl: "./images/slots/slots-imgs/Wild-Wild-Chest.png",
    name: "Wild Wild Chest",
  },
  slots166: {
    redirectUrl: "/casino/ezugi/WinEscalator",
    imgUrl: "./images/slots/slots-imgs/Win-Escalator.png",
    name: "Win Escalator",
  },
  slots167: {
    redirectUrl: "/casino/ezugi/YucatanMystery",
    imgUrl: "./images/slots/slots-imgs/Yucatan`s-Mystery.png",
    name: "Yucatan`s Mystery",
  },
};
